<template>
    <div class="media-group" :class="{ open : open }" >
        <div class="group" :class="{ open : open, comp: media.type === 'company' }" @click="toggle" v-ripple>
          <img :src="media.img" @error="errorLoad" loading="lazy" />
          <span> {{media.name}} </span>
        </div>

        <transition name="fade">
            <div class="media" v-if="open">
                <div class="content-row" v-if="content">
                      <movie v-for="(content, i) in content.media" :key="i" :media="content" :list="true" :display="true" />
                </div>
            </div>
        </transition>

        <div class="catcher" @click="close" v-if="open"/>
    </div>

</template>

<script>
const Movie = () =>  import('../components/Movie.vue')

export default {
  name: 'mediagroup',
  components: {
    Movie
  },

  props: {
    media: Object,
  },

  data: function(){
    return {
        open: false,
        ready: true,
        content: null,
    }
  },

  methods: {
    errorLoad(){
        this.ready = false
    },

    async toggle(){
        let cache = this.open
        this.$root.$emit('groupOpen') 
        this.open = !cache;

        if(!this.content){
            const request =  await fetch(process.env.VUE_APP_SERVER + `/discover`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    page: 1,
                    query: 'with_people=' + this.media.id,
                    type: 'movie',
                })
            })
            this.content =  await request.json()
        }
    },

    close(){
        this.open = false
    }

  },

  mounted: function(){
      this.$root.$on('groupOpen', this.close)
  }

}
</script>

<style scoped lang="scss">

.media-group{
    margin-left: -7.5px;
    &.open{

        .catcher{
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
        }
            &:before{
                position: absolute;
                width: 100vw;
                height: 115px;
                content: '';
                background: #141414;
                opacity: 0.95;
                left: 0;
                z-index: 2;
            }
    }

    .media{
        position: absolute;
        width: 100vw;
        left: 0;
        height: 225px;
        margin-top: 10px;
        overflow: hidden;
        padding: 15px 10px;
        background: #111;
       
        z-index: 9;
        box-shadow: 8px 4px 8px 5px rgba(0, 0, 0, 0.94);
    }

    .group{
        padding-right: 7.5px;
        padding-left: 7.5px;
        overflow: hidden;
        border-radius: 10px;

        &.open{
            position: relative;
            z-index: 9;
            img{
                border-color: rgba(white, 0.6);
            }
        }

        img{
            width: 60px;
            height: 60px;
            border: 2px solid rgba(white, 0);
            border-radius: 50%;
            margin: 0 auto;
            display: block;
            object-fit: cover;
        }
        &.comp{
            img{
                border-radius: 8px;
                padding: 10px;
                margin: 0 auto;
                background-color: white;
                height: 40px;
                width: auto;
                border: none;
            }
        }
        span{
            font-size: 11px;
            text-align: center;
            padding-top: 15px;
            font-weight: bold;
            width: 80%;
            margin: 0 auto;
            display: block;
        }
    }
}

</style>
